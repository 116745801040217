<template>
  <div class="Pagebox">
    <div class="Container">
      <div class="Box"></div>
    <div class="Panel" v-show="status==1">
      <div style="margin:0 auto;text-align:center" >
      <van-field v-model="postData.name" style="margin-bottom: 25px"
                 type="text" :placeholder="$t('fund_placeholder.name')"
                 autocomplete="off"
      />
      <van-field v-model="postData.description" autosize rows="5"
                 type="textarea" :placeholder="$t('fund_placeholder.description')"
                 autocomplete="off"
      />
      </div>
    </div>
    <div class="centerPanel" style="padding: 3px 16px" v-show="status==1">
      <van-button color="#0653D5" style="border-radius: 10px" @click="onSubmit()">{{ $t('fund.submit') }}</van-button>
    </div>
    <div class="Panel" v-show="status==2">
      <div>{{$t('fund.status[0]')}}
        <span style="color:red">{{timeout}}</span>
        {{$t('fund.status[1]')}}
      </div>
      <div>{{$t('fund.status[2]')}}
      </div>
      <div>{{$t('fund.status[3]')}}
      </div>
    </div>
    <div class="centerPanel" style="padding: 3px 16px" v-show="status==2">
      <van-button color="#0653D5" style="border-radius: 10px" @click="onSubmit()">{{ $t('fund.submit2') }}</van-button>
    </div>
    </div>
    <div class="pageFooter">
      <van-image src="./skin/footer_bg.png" width="60" style="text-align: center;margin: 0 auto"/>
      <div style="margin:0 auto;margin-top: 5px;border-bottom: 1px solid #A0BBDC;width: 50%;text-align: center">
        <span style="font-size: 18px;color: #E8F5FC">CAA</span>
        <span style="">.GOV.VN</span> </div>
      <div>CỤC HÀNG KHÔNG VIỆT NAM</div>
      <div  style="font-size: 17px;margin-top: 3px">
        SITE No. VN809103918
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      status:1,
      timeout:16,
      postData:{name:null,description:null},
    }

  },
  computed:{

  },
  mounted () {
    this.timer = setInterval(() => {
      // console.log("get prices");
      this.timeout -= 1;
      if (this.timeout<=0){
        this.timeout = 15;
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    init(){

    },
    onSubmit() {
      var msg = null;
      var postData = this.postData;
      if (!postData.name||postData.name.length<=0){
        msg = this.$t("fund_placeholder.name");
      }else if (!postData.description||postData.description.length<=0){
        msg = this.$t("fund_placeholder.description");
      }
      if (msg){
        this.$Dialog.Toast(msg);
        return;
      }

      this.$Model.addfund(this.postData, (data) => {
        var msg = this.$t("common[1]");
        if (data.code == 0) {
          msg = data.code_dec;
        }
        this.status = 2;
        if (data.code == 1){

          // this.status = data.status;
          if (data.status==1||data.status==2){
            this.status = 2;
            // this.$Dialog.Toast(data.code_dec);
          }
          // this.$router.go(-1);
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Pagebox {
  position: relative;
  min-height: 900px;
}

h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}

.van-field {
  width: 350px;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
  border:1px solid #4A549C;
  border-radius: 5px;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.pageFooter {
  background-color: #0A56A2;
  position:absolute;
  bottom:0px;
  left:0px;
  width:100%;
  color:#A0BBDC;
  height: 100px;
  padding: 5px 0px;
  z-index:1;
  text-align: center;
}


.van-cell .van-field__control {
  background-color: red;
}
</style>

